import React, { useState, useEffect } from "react"
import { AllCategoryStyles } from "./style"
import { getCategories } from "../../../services/course"
import { updateQueryParams } from "../../../services/utils"

const AllCategory = () => {
  const [activeId, setActiveId] = useState(-1)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    getCategories().then(resp => {
      const data = resp.data?.results
      setCategories(data)
    })
  }, [])

  return (
    <AllCategoryStyles className="layout">
      <h3>Kategori</h3>
      <div className="category-list">
        <div
          className={`one-category ${activeId === -1 && "active"}`}
          onClick={() => {
            updateQueryParams({ key: "cat", value: "" })
            setActiveId(-1)
          }}
        >
          <span>Semua</span>
        </div>
        {categories.map(({ id, name, thumbnail }, index) => (
          <div
            className={`one-category ${activeId === index && "active"}`}
            key={index}
            onClick={() => {
              updateQueryParams({ key: "cat", value: id })
              setActiveId(index)
            }}
          >
            <span>{name}</span>
          </div>
        ))}
      </div>
    </AllCategoryStyles>
  )
}

export default AllCategory
