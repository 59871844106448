import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  @keyframes ldio-csd7etyjcg-o {
    0% {
      opacity: 1;
      transform: translate(0 0);
    }
    49.99% {
      opacity: 1;
      transform: translate(24px, 0);
    }
    50% {
      opacity: 0;
      transform: translate(24px, 0);
    }
    100% {
      opacity: 0;
      transform: translate(0, 0);
    }
  }
  @keyframes ldio-csd7etyjcg {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(24px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .ldio-csd7etyjcg div {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .ldio-csd7etyjcg div:nth-child(1) {
    background: #ffce00;
    animation: ldio-csd7etyjcg 1s linear infinite;
    animation-delay: -0.5s;
  }
  .ldio-csd7etyjcg div:nth-child(2) {
    background: #181c25;
    animation: ldio-csd7etyjcg 1s linear infinite;
    animation-delay: 0s;
  }
  .ldio-csd7etyjcg div:nth-child(3) {
    background: #ffce00;
    animation: ldio-csd7etyjcg-o 1s linear infinite;
    animation-delay: -0.5s;
  }
  .loadingio-spinner-dual-ball-3by77rs284y {
    width: 24px;
    height: 24px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-csd7etyjcg {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-csd7etyjcg div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
`

const Spinner = () => {
  return (
    <Styles>
      <div className="ldio-csd7etyjcg">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Styles>
  )
}

export default Spinner
