import { useState, useEffect, useRef } from "react"

const useOnScreen = ref => {
  const [isVisible, setIsVisible] = useState(false)
  const observerRef = useRef(null)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsVisible(entry.isIntersecting)
    )
  }, [])

  useEffect(() => {
    observerRef.current.observe(ref.current)

    return () => {
      observerRef.current.disconnect()
    }
  }, [ref])

  return isVisible
}

export default useOnScreen

// const useIntersection = (element, rootMargin) => {
//   const [isVisible, setState] = useState(false)

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setState(entry.isIntersecting)
//       },
//       { rootMargin }
//     )

//     element && observer.observe(element)

//     return () => observer.unobserve(element)
//   }, [])

//   return isVisible
// }

// export default useIntersection
