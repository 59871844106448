import styled from "styled-components"

export const AllCategoryStyles = styled.div`
  padding-top: 50px;

  .category-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .one-category {
    display: flex;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
    margin: 0.3rem;
    cursor: pointer;

    span {
      display: block;
      padding: 2px 1rem;
    }
  }

  .kategori-img {
    width: 24px;
  }

  .one-category:hover {
    opacity: 0.6;
  }

  .active {
    color: white;
    background-color: #f7b717;
  }
`
