import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AllCourses from "../components/AllCourses"
import AllCategory from "../components/AllCourses/AllCategory"
import { getCourses, getCategoryById } from "../services/course"
import { getParameterByName } from "../services/utils"
import Spinner from "../components/Reusable/Spinner"
import useOnScreen from "../components/Hooks/useIntersection"
import axios from "axios"

const SearchPage = () => {
  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")
  const [cat, setCategory] = useState("")
  const [catName, setCatName] = useState("")
  const [count, setCount] = useState(0)
  const [next, setNext] = useState(null)
  const elementRef = useRef(null)
  const isOnScreen = useOnScreen(elementRef)

  useEffect(() => {
    if (isOnScreen && next) {
      setLoading(true)
      axios.get(next).then(resp => {
        setCourses([...courses, ...resp.data?.results])
        setNext(resp?.data?.next)
        setLoading(false)
      })
    }
  }, [isOnScreen])

  useEffect(() => {
    setLoading(true)
    getCourses({ query, cat }).then(resp => {
      setCourses(resp.data?.results)
      setCount(resp.data?.count)
      setLoading(false)
      setNext(resp?.data?.next)
    })
  }, [cat, query])

  useEffect(() => {
    getCategoryById(cat).then(resp => {
      setCatName(resp?.data?.name)
    })
  }, [cat])

  useEffect(() => {
    setQuery(getParameterByName("q"))
    setCategory(getParameterByName("cat"))
  }, [typeof window !== `undefined` && window.location.href])

  return (
    <Layout>
      <SEO title="Search" />
      <AllCategory />
      <div
        className="layout"
        style={{
          paddingTop: "25px",
        }}
      >
        <h3>Hasil Penelusuran</h3>
        {!loading && (
          <p>
            {count ? `Menampilkan ${count} kursus` : "Tidak ditemukan kursus"}
            {query && (
              <span>
                {" "}
                untuk <b>{query}</b>
              </span>
            )}
            {cat && (
              <span>
                {" "}
                dalam kategori <b>{catName}</b>
              </span>
            )}
            .
          </p>
        )}
      </div>

      {courses && <AllCourses courses={courses} />}

      {loading && (
        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}

      <div ref={elementRef}></div>
    </Layout>
  )
}

export default SearchPage
